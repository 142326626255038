import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardBookingCheckout from './dashboardBookingCheckout'
import CarGroupFeatures from 'view/pages/rentingPage/booking/carGroupFeatures/carGroupFeatures'
import { useBooking } from 'aplication/useCases/booking/useBooking'
import { useAuthContext } from 'view/contexts/authContext'
import ResourcesList from 'view/componentsPages/resourcesList/resourcesList'
import ProtectionInfo from 'view/componentsPages/protectionInfo/protectionInfo'
import Booking from 'view/componentsPages/booking/booking'
import { BookingStatus } from 'view/componentsPages/bookingStatus'
import LocationDateTime from 'view/componentsPages/locationDateTime/locationDateTime'
import DashboardBookingDrivers from '../dashboardBookingDrivers'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { isoDateToDateDisplay } from '../../../../../aplication/utils/app/dateTimeCare/dateTimeCare'
import './dashboardBookingSingle.css'
import BookingCardBookingCode from '../../components/bookingCardBookingCode/bookingCardBookingCode'

const DashboardBookingSingle: React.FC = () => {
  const { loggedUser } = useAuthContext()
  const { booking, loaded, readBooking } = useBooking()
  const params: any = useParams()

  useEffect(() => {
    readBooking(loggedUser?.person.uuid, params.id)
  }, [loggedUser, params])

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent height={600} />
        </div>
      )
    }
    return (
      <Booking
        carGroup={booking.carGroup}
        head={
          <div className="booking-head-info">
            <div className="booking-head-info row">
              <span className="booking-create-date">
                <b>Criada em: </b>
                {isoDateToDateDisplay(booking.createdAt)}
              </span>
            </div>
            <div className="booking-head-info row">
              <BookingCardBookingCode {...{ booking }} />
              <BookingStatus status={booking.status} />
            </div>
          </div>
        }
        content={
          <>
            <CarGroupFeatures features={booking.carGroup?.features} />
            <ResourcesList resources={booking?.resourcesDetail} />
            <DashboardBookingDrivers drivers={booking?.drivers} />
          </>
        }
        sidebar={
          <>
            <LocationDateTime
              pickupData={booking?.pickup}
              returnData={booking?.return}
            />
            <ProtectionInfo protection={booking?.protection} />
          </>
        }
        footer={<DashboardBookingCheckout booking={booking} />}
      />
    )
  }

  return <>{renderContent()}</>
}

export default DashboardBookingSingle
