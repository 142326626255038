import { CarGroupApiService } from 'aplication/apiServices/carGroupApiService/carGroupApiService'
import { TCarGroup } from 'domain/entities/TCarGroup'
import { useState } from 'react'
import { carGroupDataParser } from './carGroupDataParser'

export type TCarGroupReader = {
  data: TCarGroup
  loaded: boolean
  load: (id: string) => void
}

export function carGroupReader(): TCarGroupReader {
  const [data, setData] = useState<TCarGroup>({} as TCarGroup)
  const [loaded, setLoaded] = useState<boolean>(false)

  function load(id: string) {
    setLoaded(false)
    if (!id) return
    const apiService = new CarGroupApiService()
    apiService.read(id).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        setLoaded(true)
        return
      }
      const parsedData = carGroupDataParser(response.body)
      setData(parsedData)
      setLoaded(true)
    })
  }

  return {
    data,
    loaded,
    load
  }
}
