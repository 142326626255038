export function zeroOnLeft(number: number): string {
  return number < 10 ? `0${number}` : `${number}`
}

/**
 * @returns dd/mm/yyyy
 */
export function isoDateToDateDisplay(date?: string): string {
  if (!date) return ''
  const newDate = new Date(date)
  return newDate.toLocaleDateString('pt-BR')
}

export function isToday(date: Date): boolean {
  const currentDate = new Date()
  return (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  )
}

export function isoDateToAmericanDate(
  date?: string,
  separator?: string
): string {
  separator = separator || '-'
  if (!date) return ''
  if (!date || date === '') return ''
  date = date.replace('Z', '')
  const newDate = new Date(date)

  const fulldate = [
    zeroOnLeft(newDate.getDate()),
    zeroOnLeft(newDate.getMonth() + 1),
    newDate.getFullYear()
  ]

  return `${fulldate[2]}${separator}${fulldate[1]}${separator}${fulldate[0]}`
}

export function dateToIsoDate(date: any): string {
  if (!date) return ''
  return date.toISOString()
}

/**
 * converte do formato "Date" para "[yyyy]-[m]-[d]"
 */
export function dateToAmericanDate(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function isoDateToDate(isoDate?: string): Date | undefined {
  if (!isoDate) return undefined
  return new Date(isoDate)
}

export function americanDateToDate(americanDate?: string): Date | undefined {
  if (!americanDate || americanDate === 'NaN-NaN-NaN') return undefined
  return new Date(americanDate)
}

/**
 * return DD/MM/YYYY
 */
export function americanDateToFormat(americanDate?: string): string {
  if (!americanDate || americanDate === 'NaN-NaN-NaN') return ''
  const date = americanDate.split('-')
  return `${date[2]}/${date[1]}/${date[0]}`
}

/**
 * @returns mm:ss
 */
export function isoDateToTimeDisplay(date?: string): string {
  if (!date) return ''
  const newDate = new Date(date)
  const time = newDate.toLocaleTimeString('pt-BR', {
    timeZone: 'America/Sao_Paulo'
  })
  return time.replace(':00', '')
}

/**
 * Retorna de um "Date" "[h]:[m]:[s]"
 */
export function dateToFullTimeString(dateTime: Date): string {
  return `${dateTime.getHours()}:${zeroOnLeft(
    dateTime.getMinutes()
  )}:${zeroOnLeft(dateTime.getSeconds())}`
}

export function newDateByTimeString(timeValue: string): Date {
  return new Date('0001/01/01 ' + timeValue + ':00')
}

export function timeIsBefore(time: Date, reference: Date): boolean {
  function extractTimeParts(date: Date) {
    return {
      hours: date.getHours(),
      seconds: date.getSeconds()
    }
  }

  const timeParts = extractTimeParts(time)
  const referenceParts = extractTimeParts(reference)

  if (timeParts.hours === referenceParts.hours) {
    return timeParts.seconds <= referenceParts.seconds
  }

  return timeParts.hours <= referenceParts.hours
}

/**
 * Retorna diferença entre datas sem aproximação
 */
export function diffBetweenDatesNotRound(
  firstDate?: Date,
  lastDate?: Date
): number {
  if (!firstDate || !lastDate) return 0
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  return Math.abs((firstDate.getTime() - lastDate.getTime()) / oneDay)
}

/**
 * Retorna diferença entre datas, com aproximação
 */
export function diffBetweenDates(firstDate?: Date, lastDate?: Date): number {
  return Math.round(diffBetweenDatesNotRound(firstDate, lastDate))
}

/**
 * Retorna a diferença de horas entre datas
 */
export function diffHoursBetweenDates(firstDate: Date, lastDate: Date): number {
  if (!firstDate || !lastDate) return 0
  const firstDateTime = firstDate.getTime()
  const lastDateTime = lastDate.getTime()
  const diff = (firstDateTime - lastDateTime) / 36e5
  return Math.abs(diff)
}

export function getStringDateByDate(date: Date): string {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  return `${year}-${month + 1}-${day}`
}

export function getStringTimeByDate(date: Date): string {
  return date.toLocaleTimeString('pt-BR', {
    timeZone: 'America/Sao_Paulo'
  })
}

export function mergeDateAndTime(date: Date, time: Date): Date {
  return new Date(`${getStringDateByDate(date)} ${getStringTimeByDate(time)}`)
}

export function addDays(date: string, days: number): string {
  return date
    .split('-')
    .map((value, index) => (index === 2 ? parseInt(value) + days : value))
    .join('-')
}
/**
 * Cria um novo "Date" a partir de uma data de um "Date" e um horário de outro "Date"
 * Para unir valores de data com horário, que são registrados em fields diferentes
 */
export function mergeDateDateAndTimeDate(dateDate: Date, timeDate: Date): Date {
  const date = dateToAmericanDate(dateDate)
  const time = dateToFullTimeString(timeDate)
  // esta conversão soluciona bug em dispositivos mobile iOS e browser safari
  const arr: any[] = `${date} ${time}`.split(/[- :]/)
  const newDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
  return newDate
}

/**
 * verifica se "date" está antes de "reference"
 */
export function dateIsBefore(date: Date, reference: Date): boolean {
  const dateAsTime = date.getTime()
  const referenceAsTime = reference.getTime()

  return dateAsTime < referenceAsTime
}

/**
 * Retorna o total de horas extras
 */
export function getExtraHours(firstDate?: Date, lastDate?: Date): number {
  if (!firstDate || !lastDate) return 0
  const bestCloseDaily = mergeDateDateAndTimeDate(lastDate, firstDate)
  if (dateIsBefore(lastDate, bestCloseDaily)) return 0
  const oneHour = 60 * 60 * 1000 // minutes*seconds*milliseconds
  const totalHours = Math.abs(
    (firstDate.getTime() - lastDate.getTime()) / oneHour
  )
  const totalFullDays = Math.trunc(totalHours / 24)
  return Math.round((totalHours / 24 - totalFullDays) * 24)
}
/**
 * retorn time em formato 00:00:00
 */
export function getFullTime(time: Date): string {
  const hour = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
  const minutes =
    time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
  const seconds =
    time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds()

  return `${hour}:${minutes}:${seconds}`
}
