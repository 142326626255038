import React, { useEffect, useRef } from 'react'
import { useBooking } from 'aplication/useCases/booking/useBooking'
import { useHistory, useParams } from 'react-router-dom'
import Booking from 'view/componentsPages/booking/booking'
import { useAuthContext } from 'view/contexts/authContext'
import CarGroupFeatures from 'view/pages/rentingPage/booking/carGroupFeatures/carGroupFeatures'
import ResourcesList from 'view/componentsPages/resourcesList/resourcesList'
import LocationDateTime from 'view/componentsPages/locationDateTime/locationDateTime'
import ProtectionInfo from 'view/componentsPages/protectionInfo/protectionInfo'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { Modal, TModalRef } from 'view/components/modal'
import { TBookingEvent } from 'domain/entities/TBooking'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'
import Button from 'view/components/button/button'
import './dashboardBookingEdit.css'
import DashboardBookingForm from './dashboardBookingEditForm'
import DashboardBookingEditCheckout from './dashboardBookingEditCheckout'
import { usePageLoader } from 'view/hooksPages'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import DashboardBookingDrivers from '../dashboardBookingDrivers'

const DashboardBookingEdit: React.FC = () => {
  const history = useHistory()
  const params: any = useParams()
  const modalRef = useRef<TModalRef>({} as TModalRef)

  const { loaded } = usePageLoader()
  const { loggedUser } = useAuthContext()
  const { booking, readBooking } = useBooking() // API
  const { bookingData, updateBookingData } = useBookingDataContext() // Contexto
  const { carGroupPriceRule, priceRule } =
    useBookingValuesContext().carGroupPriceRules

  function startBookingData() {
    if (!booking || objectIsEmpty(booking)) return
    if (booking.status !== 'em_andamento') history.push('/dashboard')

    updateBookingData({
      ...booking,
      pickup: {
        date: booking.return?.date || '',
        hour: booking.return?.hour || '',
        local: booking.return?.location?.id
      },
      return: {} as TBookingEvent,
      daily: 0,
      dailyTotal: 0,
      totalToPay: 0,
      dailyValue: 0
    })

    setTimeout(() => {
      modalRef.current.toggleOpen()
    }, 500)
  }

  function updateDailyValue() {
    if (
      bookingData.dailyValue ||
      !bookingData ||
      !bookingData.pickup ||
      !bookingData.pickup.date ||
      !bookingData.return ||
      !bookingData.return.date ||
      objectIsEmpty(bookingData) ||
      !booking.carGroup ||
      !booking.carGroup.price
    ) {
      return
    }
    const dailyValue = carGroupPriceRule(booking.carGroup.price)
    updateBookingData({ dailyValue })
  }

  // // lê o booking vindo da API
  useEffect(() => {
    if (!loggedUser || objectIsEmpty(loggedUser)) return
    readBooking(loggedUser?.person.uuid, params.id)
  }, [loggedUser])

  // // insere o booking da API no booking do contexto
  useEffect(() => {
    startBookingData()
  }, [booking])

  useEffect(() => {
    updateDailyValue()
  }, [priceRule])

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <div className="booking-update-edit">
          <Booking
            carGroup={bookingData?.carGroup}
            content={
              <>
                <CarGroupFeatures features={bookingData.carGroup?.features} />
                <ResourcesList resources={bookingData?.resourcesDetail} />
                <DashboardBookingDrivers drivers={bookingData?.drivers} />
              </>
            }
            sidebar={
              <>
                <LocationDateTime
                  pickupData={bookingData?.pickup}
                  returnData={bookingData?.return}
                  hidePickupLocation
                  customStartDate={{
                    title: 'Início'
                  }}
                  customFinalDate={{
                    content: (
                      <Button
                        className="booking-update-edit-return"
                        size="sm"
                        style="orange"
                        onClick={() => modalRef.current.toggleOpen()}
                      >
                        Editar
                      </Button>
                    )
                  }}
                />
                <ProtectionInfo protection={bookingData?.protection} />
              </>
            }
            footer={<DashboardBookingEditCheckout />}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        className="booking-update-edit-form"
        content={<DashboardBookingForm />}
        ref={modalRef}
      />
      {renderContent()}
    </>
  )
}

export default DashboardBookingEdit
