import React from 'react'
import { TBooking } from 'domain/entities/TBooking'
import Card from 'view/components/card/card'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { CarouselPageHeader } from '../carouselPageHeader'
import { TRentingData } from 'domain/entities/TRentingData'
import './booking.css'
import { TCarGroup } from 'domain/entities/TCarGroup'
import { ICarGroup } from 'domain/entities/ICarGroup'

type TProps = {
  // booking: TBooking | TRentingData
  carGroup?: ICarGroup | TCarGroup | null
  head?: JSX.Element
  content?: JSX.Element
  sidebar?: JSX.Element
  footer?: JSX.Element
}

const Booking: React.FC<TProps> = ({
  // booking,
  carGroup,
  content,
  sidebar,
  footer,
  head
}) => {
  return (
    <div className="booking-page">
      <div className="container">
        <TitlePage>{carGroup?.name}</TitlePage>
        {head}
      </div>
      <CarouselPageHeader images={carGroup?.img} />
      <div className="container">
        <div className="booking-sections">
          <div className="booking-content">
            <div className="car-group-details">
              {carGroup?.description || ''}
            </div>
            {content}
          </div>
          <div className="booking-sidebar">
            <Card>
              <Card.content>{sidebar}</Card.content>
            </Card>
          </div>
        </div>
        <div className="booking-abstract">{footer}</div>
      </div>
    </div>
  )
}

export default Booking
