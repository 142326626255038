import { User } from 'aplication/entities/User'
import { diffBetweenDates } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TEmpty } from 'domain/entities/TEmpty'
import { priceRulesApi } from '../priceRulesApi/priceRulesApi'

function selectDiscountName(totalDays: number): string | null {
  if (totalDays <= 2) {
    return 'desconto_1a2diarias'
  } else if (totalDays >= 3 && totalDays <= 6) {
    return 'desconto_3a6diarias'
  } else if (totalDays >= 7 && totalDays <= 14) {
    return 'desconto_7a14diarias'
  } else if (totalDays >= 15) {
    return 'desconto_acima15diarias'
  }

  return null
}

/**
 * está nos próximos 7 dias?
 */
export function inSevenDays(date: Date): boolean {
  const currentDate = new Date()
  return diffBetweenDates(currentDate, date) < 7
}

/**
 * está além de 7 até 15 dias da data de hoje?
 */
export function inFiffteenDays(date: Date): boolean {
  const currentDate = new Date()
  const diff = diffBetweenDates(currentDate, date)
  return diff >= 7 && diff <= 14
}

/**
 * está além de 15 dias da data de hoje?
 */
export function inFiffteenDaysBeyond(date: Date): boolean {
  const currentDate = new Date()
  const diff = diffBetweenDates(currentDate, date)
  return diff >= 15
}

/**
 * verifica se período da reserva está contido em um feriado/periodo
 * @param rules regras de desconto da api
 */
export function inHoliday(
  rules: any[],
  startDate: Date,
  finalDate: Date
): any[] | null {
  const totalDays = diffBetweenDates(startDate, finalDate)
  const discontName = selectDiscountName(totalDays)
  // if (!rules || totalDays >= 15) return null

  const rulesFound = rules.filter(rule => {
    const startHoliday = new Date(
      `${rule.periodo_feriado.data_inicial} 00:00:00`
    )
    const endHoliday = new Date(`${rule.periodo_feriado.data_final} 00:00:00`)
    return startDate <= endHoliday && finalDate >= startHoliday
  })

  if (discontName && rulesFound.length > 0) {
    // retorna o menor disconto
    return rulesFound.reduce((n, rule) => {
      if (rule[discontName] !== null && n[discontName] >= rule[discontName]) {
        return rule
      }
      return n
    })
  }

  return null
}

/**
 * coleta a regra de desconto da api
 */
async function getPriceRule(ruleName: string): Promise<any> {
  const response = await priceRulesApi(ruleName)
  return response?.body?.results
}

/**
 * seleciona a regra de desconto a ser aplicada
 */
export async function returnPriceRules(
  startDate: Date,
  finalDate: Date
): Promise<any> {
  let ruleName = ''
  const rulesHollyday = await getPriceRule('hollydays')
  const ruleHoliday = await inHoliday(rulesHollyday, startDate, finalDate)
  if (ruleHoliday) {
    return ruleHoliday
  } else if (inSevenDays(startDate)) {
    ruleName = 'sevenDays'
  } else if (inFiffteenDays(startDate)) {
    ruleName = 'fiffteenDays'
  } else if (inFiffteenDaysBeyond(startDate)) {
    ruleName = 'fiffteenDaysBeyond'
  }
  const priceRules = await getPriceRule(ruleName)
  return priceRules[0]
}

/**
 * aplica o desconto no preço do grupo
 * @param rule regra de desconto (api)
 */
export function applyPriceRule(
  price: number,
  rule: any,
  startDate: Date,
  finalDate: Date
): number {
  const diffDates = diffBetweenDates(startDate, finalDate)
  let discontName = ''

  if (diffDates <= 2) {
    discontName = 'desconto_1a2diarias'
  } else if (diffDates >= 3 && diffDates <= 6) {
    discontName = 'desconto_3a6diarias'
  } else if (diffDates >= 7 && diffDates <= 14) {
    discontName = 'desconto_7a14diarias'
  } else if (diffDates >= 15) {
    discontName = 'desconto_acima15diarias'
  }
  return rule[discontName]
}

export function calcPriceValue(
  price: number,
  discount: number
): { newPrice: number; discountValue: number } {
  const discountValue = price * (discount / 100)
  const newPrice = price - discountValue
  return { newPrice, discountValue }
}

export function addPJDiscount(
  discountPercent: number,
  loggedUser: User | TEmpty | null
): number {
  if (!loggedUser || !loggedUser.person) return discountPercent
  const clienttype = loggedUser.person.clientType
  const clientDiscount = loggedUser.person.clientDiscount
  if (!clientDiscount || !clienttype || clienttype !== 'PJ') {
    return discountPercent
  }
  return discountPercent + clientDiscount
}
