import { timeIsBefore } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import React, { useEffect, useState } from 'react'
import TimeField from 'view/components/formComponent/fields/timeField/timeField'
import { useFormContext } from 'view/components/formComponent/formComponent'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingFormContext } from '../bookingFormProvider'
import { bookingRulesValidatior } from 'aplication/useCases/rentalRulesValidator/bookingRulesValidator'

type Props = {
  name: string
  listeningLocation: string
  listeningDate: string
  disabled?: boolean
  minTime?: Date
  fieldRef?: any
  onChange?: (value: any) => void
}

const BookingTimeField: React.FC<Props> = ({
  listeningLocation,
  listeningDate,
  name,
  disabled,
  minTime,
  fieldRef,
  onChange
}) => {
  const { bookingDateBlocks } = useBookingFormContext()
  const { locations } = useAppContext().locations
  const { fields, updateField } = useFormContext()
  const { timeValidation, timeFilterInBookingDateBlockPeriod } =
    bookingRulesValidatior(locations)
  const [locationValue, setLocationValue] = useState<any>('')
  const [dateValue, setDateValue] = useState<any>('')
  const [beforeDateValue, setBeforeDateValue] = useState<any>('')

  function filterMinimalTime(currentTime: Date, referenceTime?: Date) {
    if (!referenceTime) return true
    return timeIsBefore(referenceTime, currentTime)
  }

  function makeTimeFilter(location: string, selectedDate: Date) {
    return (currentTime: Date) => {
      return [
        timeValidation(currentTime, selectedDate, location),
        timeFilterInBookingDateBlockPeriod(
          currentTime,
          selectedDate,
          location,
          bookingDateBlocks
        ),
        filterMinimalTime(currentTime, minTime)
      ].every(i => i)
    }
  }

  function handleChange(value: any) {
    if (onChange) onChange(value)
  }

  useEffect(() => {
    const locationField = fields.find(field => field.name === listeningLocation)
    if (locationField) {
      setLocationValue(locationField.value)
    }
    const dateField = fields.find(field => field.name === listeningDate)
    if (dateField && dateField.value !== dateValue) {
      setBeforeDateValue(dateValue)
      setDateValue(dateField.value)
    }
  }, [fields])

  useEffect(() => {
    if (dateValue && beforeDateValue) {
      updateField({ name, value: '' })
    }
  }, [dateValue])

  return (
    <TimeField
      name={name}
      filter={makeTimeFilter(locationValue, dateValue)}
      disabled={disabled}
      required
      disableWrite
      onChange={handleChange}
      fieldRef={fieldRef}
    />
  )
}

export default BookingTimeField
