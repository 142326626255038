import React, { createContext, useEffect } from 'react'
import Additional from './additional/additional'
import { usePageLoader, useRentingDataValidation } from 'view/hooksPages'
import BookingCheckout from './bookingCheckout'
import CarGroupFeatures from './carGroupFeatures/carGroupFeatures'
import ProtectionInfo from 'view/componentsPages/protectionInfo/protectionInfo'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import Booking from 'view/componentsPages/booking/booking'
import LocationDateTime from 'view/componentsPages/locationDateTime/locationDateTime'
import { BookingDriversList } from './drivers/bookingDriversList'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import {
  TUseEventController,
  useEventController
} from 'aplication/useCases/useEventController/useEventController'
import './booking.css'
import { SubTitle } from 'view/components'
import CarFeaturesTags from 'view/componentsPages/carFeaturesTags/carFeaturesTags'
import Card from 'view/components/card/card'
import { carGroupReader } from 'aplication/useCases/carGroup/carGroupReader'

type TBookingViewContext = {
  eventController: TUseEventController
}

export const BookingViewContext = createContext<TBookingViewContext>(
  {} as TBookingViewContext
)

const CarGroupView: React.FC = () => {
  const { bookingData } = useBookingDataContext()
  const { loaded } = usePageLoader()
  const { invalidData, goToInvalidPage } = useRentingDataValidation([
    'dateTimeValidator',
    'carGroupValidator',
    'pickupValidation'
  ])
  const eventController = useEventController()

  const carGroup = carGroupReader()

  useEffect(() => {
    if (!bookingData.carGroup?.uuid) return
    carGroup.load(bookingData.carGroup?.uuid)
  }, [])

  function renderContent() {
    if (!loaded) {
      return (
        <>
          <div className="container">
            <LoadingContent />
          </div>
        </>
      )
    } else if (loaded && invalidData) {
      goToInvalidPage()
    } else {
      return (
        <BookingViewContext.Provider value={{ eventController }}>
          <Booking
            carGroup={carGroup.data}
            head={
              <>
                <CarFeaturesTags features={carGroup?.data?.features} />
              </>
            }
            content={
              <>
                <CarGroupFeatures features={carGroup?.data?.features} />
                <Additional />
                <BookingDriversList />
              </>
            }
            sidebar={
              <>
                <LocationDateTime
                  canEdit
                  pickupData={bookingData?.pickup}
                  returnData={bookingData?.return}
                />
                <ProtectionInfo protection={bookingData.protection} />
              </>
            }
            footer={
              <>
                <h2 className="booking-checkout-title">Resumo da reserva</h2>
                <BookingCheckout />
              </>
            }
          />
        </BookingViewContext.Provider>
      )
    }
  }

  return <>{renderContent()}</>
}

export default CarGroupView
