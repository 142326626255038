import { TBookingCardData } from 'domain/entities/TBooking'
import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import {
  FormComponent,
  InputField,
  useFormContext
} from 'view/components/formComponent'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import { InputCardField } from '../customFields'
import InputMothYearField from '../customFields/inputMonthYear/inputMonthYear'
import SelectPaymentFormat from './selectPaymentFormat'
import CredCardsIconsList from 'view/components/credCardsIcons/credCardsIconsList'

type TProps = {
  onSubmit: (values: TBookingCardData) => void
  newCardBtnAction: () => void
  hasCard?: boolean
}

const CredCardsIconListListenner: React.FC = () => {
  const { fields } = useFormContext()
  const [number, setNumber] = useState<number>()

  useEffect(() => {
    const cardNumber = fields.find(field => field.name === 'cardNumber')
    if (!cardNumber || !cardNumber.value) return
    if (cardNumber.value.length >= 19) {
      setNumber(cardNumber.value)
    }
  }, [fields])

  return <CredCardsIconsList cardnumber={`${number}`} />
}

const BookingPaymentFormForm: React.FC<TProps> = ({
  onSubmit,
  newCardBtnAction,
  hasCard
}) => {
  function renderUseRegistredCard() {
    if (!hasCard) return <></>
    return (
      <Button
        className="use-card-btn"
        style="transparent"
        onClick={newCardBtnAction}
      >
        Usar um cartão cadastrado
      </Button>
    )
  }

  return (
    <>
      <h2>Cartão de Crédito</h2>

      <FormComponent onSubmit={onSubmit}>
        <div className="form-session">
          <InputField
            name="cardName"
            placeholder="Nome impresso no cartão"
            required
            maxLength={200}
            autoComplete="off"
          />
          <InputCardField required />
          <CredCardsIconListListenner />
        </div>
        <div className="form-session">
          <InputMothYearField required />
          <PasswordField
            name="cardSecurityCode"
            placeholder="Código"
            maxLength={3}
            required
            autoComplete="off"
          />
          <SelectPaymentFormat />
          <InputField type="hidden" name="cardBrand" />
        </div>
        <div className="form-session">
          <Button type="submit" size="bg" full>
            Confirmar
          </Button>
        </div>
      </FormComponent>
      {renderUseRegistredCard()}
    </>
  )
}

export default BookingPaymentFormForm
