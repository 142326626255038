import React, { useEffect, useState } from 'react'
import CredCardsIconVisa from './credCardsIconVisa'
import CredCardsIconAExpress from './credCardsIconAExpress'
import CredCardsIconDiners from './credCardsIconDiners'
import CredCardsIconElo from './credCardsIconElo'
import CredCardsIconMasterCard from './credCardsIconMasterCard'
import { CredCardApiService } from 'aplication/apiServices/credCardApiService/credCardApiService'
import './credCardsIconsList.css'

type TProps = {
  cardnumber: string
}

const CredCardsIconsList: React.FC<TProps> = ({ cardnumber }) => {
  const [selected, setSelected] = useState<string>()

  function isSelected(flag: string) {
    return selected === flag ? 'selected' : ''
  }

  function getFlag(cardnumber: string) {
    const apiService = new CredCardApiService()
    apiService.getFlag(cardnumber).then(response => {
      if (response.statusCode === 200 && response.body.provider) {
        setSelected(response.body.provider)
      }
    })
  }

  useEffect(() => {
    if (!cardnumber || cardnumber === 'undefined') return
    getFlag(cardnumber)
  }, [cardnumber])

  return (
    <ul className="cred-card-icons">
      <li className={`cred-card-icon ${isSelected('VISA')}`}>
        <CredCardsIconVisa />
      </li>
      <li className={`cred-card-icon ${isSelected('MASTERCARD')}`}>
        <CredCardsIconMasterCard />
      </li>
      <li className={`cred-card-icon ${isSelected('AMEX')}`}>
        <CredCardsIconAExpress />
      </li>
      <li className={`cred-card-icon ${isSelected('DINERS')}`}>
        <CredCardsIconDiners />
      </li>
      <li className={`cred-card-icon ${isSelected('ELO')}`}>
        <CredCardsIconElo />
      </li>
    </ul>
  )
}

export default CredCardsIconsList
