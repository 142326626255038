import React from 'react'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import { TRentingData } from 'domain/entities/TRentingData'
import Button from 'view/components/button/button'
import './checkout.css'
import {
  isoDateToDateDisplay,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import CheckoutLocationTax from './checkoutLocationTax'

type Props = {
  onConfirm?: () => void
  action?: any
  bookingData: TRentingData
  disabled?: boolean
}

const Checkout: React.FC<Props> = ({
  onConfirm,
  action,
  bookingData,
  disabled
}) => {
  function hasConfirmAction() {
    if (onConfirm) {
      return (
        <div className="checkout-confirm">
          <Button onClick={onConfirm} size="bg" disabled={disabled}>
            Confirmar reserva
          </Button>
        </div>
      )
    }
    return ''
  }

  function totalWithoutDiscount() {
    if (!bookingData.discountTotal || !bookingData.dailyTotal) return <></>
    return priceConverter(bookingData?.discountTotal + bookingData?.dailyTotal)
  }

  function formatExtraHours(extraHours: number, totalValue: number) {
    const time = extraHours > 5 ? 1 : extraHours
    const timeValue = totalValue / time
    return `${time} x ${priceConverter(timeValue)}`
  }

  function renderExtraHours() {
    if (!bookingData.extraHour || !bookingData?.extraHourValue) return <></>
    const title = bookingData.extraHour < 6 ? 'Horas extras' : 'Diária extra'
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>{title}</h3>
          <span>
            {formatExtraHours(
              bookingData?.extraHour,
              bookingData?.extraHourValue
            )}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(bookingData?.extraHourValue)}
        </div>
      </div>
    )
  }

  function renderDrivers() {
    if (!bookingData?.driversTax || !bookingData?.driversTotalTax) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>Condutores</h3>
          <span>
            {bookingData.daily} x {priceConverter(bookingData?.driversTax)}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(bookingData?.driversTotalTax)}
        </div>
      </div>
    )
  }

  function renderResources() {
    if (!bookingData?.daily || !bookingData?.resourcesTotal) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>Acessórios</h3>
          <span>
            {bookingData?.daily || 0} x{' '}
            {priceConverter(bookingData?.resourcesTotal)}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(bookingData?.resourcesTotalDaily)}
        </div>
      </div>
    )
  }

  /**
   * exibe calculo de proteção
   * soma uma protecao em caso de hora estra
   */
  function protectionDaily() {
    const daily = bookingData.daily || 0
    if (bookingData.extraHour) return daily + 1
    return daily
  }

  return (
    <div className="checkout">
      <div className="checkout-booking">
        <h3>{bookingData?.carGroup?.name}</h3>
        <span>{bookingData?.daily} dias</span>
        <div className="checkout-booking-item">
          <span>{bookingData?.pickup?.location?.label}</span>
          <span>
            {isoDateToDateDisplay(bookingData?.pickup?.date)} -{' '}
            {isoDateToTimeDisplay(bookingData?.pickup?.hour)}
          </span>
        </div>
        <div className="checkout-booking-item">
          <span>{bookingData?.return?.location?.label}</span>
          <span>
            {isoDateToDateDisplay(bookingData?.return?.date)} -{' '}
            {isoDateToTimeDisplay(bookingData?.return?.hour)}
          </span>
        </div>
      </div>

      <div className="checkout-values">
        <div className="checkout-values-item">
          <div className="checkout-values-item-info">
            <h3>Diárias</h3>
            <span>
              {bookingData?.daily} x {priceConverter(bookingData?.dailyValue)}
            </span>
          </div>
          <div className="checkout-values-item-value">
            <small className="discount">{totalWithoutDiscount()}</small>
            <br />
            {priceConverter(bookingData?.dailyTotal)}
          </div>
        </div>

        {renderExtraHours()}

        <div className="checkout-values-item">
          <div className="checkout-values-item-info">
            <h3>Proteção</h3>
            <span>
              {protectionDaily()} x{' '}
              {priceConverter(bookingData?.protection?.price)}
            </span>
          </div>
          <div className="checkout-values-item-value">
            {priceConverter(bookingData?.protectionTotal)}
          </div>
        </div>

        {renderDrivers()}

        {renderResources()}

        <CheckoutLocationTax bookingData={bookingData} />
      </div>

      <div className="checkout-final">
        <div className="checkout-total">
          <span>Total</span>{' '}
          <span>{priceConverter(bookingData?.totalToPay)}</span>
        </div>

        {hasConfirmAction()}

        {action || ''}
      </div>
    </div>
  )
}

export default Checkout
